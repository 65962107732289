.services__title {
  font-size: 2.6rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 70px;
  margin-top: 60px;
  max-width: 1100px;
  margin-left: auto; /* Centrar horizontalmente */
  margin-right: auto; /* Centrar horizontalmente */
}

.animate-text-blue {
  color: #3538ce; /* Color azul */
}

.services {
  background: linear-gradient(#ffffff,  #d4dffc, #c7c8ff, #d4dffc, #ffffff);
  height: auto;
  padding-bottom: 0px;
  text-align: center;
  justify-content: center;
  
}
.carousel-container2{
  width: 100%;
  background: #ffffff;
 max-width: 1200px;
 height: 470px; 
 border-radius: 30px;
 margin: 0 auto; /* Centrar horizontalmente */


}

.number-row {
  display: flex;
  justify-content: center;
  flex-direction: column; /* Alinear los números uno debajo del otro */
  align-items: center; /* Centrar los números horizontalmente */

  margin-bottom: 10px;
}

.number-column {
  margin-bottom: 10px; /* Agregar un margen entre cada número */
}

.number {
  width: 65px;
  height: 65px;
  background-color: #f0f0f0; /* Color de fondo predeterminado */
  border-radius: 20%; /* Para hacerlo redondeado */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.square {
  font-size: 33px;
  color: #585858; /* Color del número */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.active {
  background-color: #cec5ff; /* Color de fondo cuando está activo */
}

.active-square {
  color: #5a39fd; /* Color del número cuando está activo */
}


.image {
    width: 100%;
    height: 100%; /* Extender la imagen en toda la altura del contenedor */
    object-fit: cover; /* Escalar la imagen para cubrir toda el área */
}

.content {
  display: flex;
}

.title-text-column {
  flex: 5;
  padding: 20px;
}

.number-column {
  flex: 2;
  padding: 20px;
}

.image-column {
  flex: 7;
  display: flex;
  align-items: center;
  justify-content: center;

}

.text{
  text-align: left; 
  font-size: 1.15rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
  color: #505153;
}

.title{
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 30px;
  text-align: left; /* Alinear el texto a la izquierda */
}

.title strong {
  font-weight: bold;
  padding: 0.2em 0.4em;
  background: #cec5ff;
  border-radius: 10px;
  color: #5a39fd; /* Violeta muy oscuro */
}

.text strong{
  color: #5a39fd;
  font-weight: bold;

}


@media (max-width: 800px) {
  .services__title,
  .animate-text-blue {
    font-size: 1.7rem;
    text-align: center; /* Centra el texto horizontalmente */
    margin-left: 0; /* Centrar horizontalmente */
    margin-right: 0; /* Centrar horizontalmente */
   

  }
}


@media (max-width: 1000px) {

  .button-container2 {
    display: flex;
    justify-content: center;
    align-items: center; /* Opcional, para centrar también verticalmente */
    margin-top: 250px; /* Ajusta el espacio superior si es necesario */
  }
  .number-row {
    display: flex;
    justify-content: center;
    flex-direction: row; /* Alinear los números uno debajo del otro */
    align-items: center; /* Centrar los números horizontalmente */
    margin-bottom: 10px;
  }

    .services {
      background: rgb(255, 255, 255);
      height: auto;
      
    }
 
    .carousel-container2{
      width: 100%;
      background: #ffffff;
     max-width: 1200px;
     height: 515px; 
     border-radius: 30px;
     margin: 0 auto; /* Centrar horizontalmente */
   
    }
    
    
    .carousel-card {
      display: grid;
      grid-template-columns: 1fr 5fr 6fr;
      background-color: white;
      border-radius: 20px; /* Agregar bordes redondeados a toda la tarjeta */
      border: 3px solid #f0f0f0 /* Color y ancho del borde */
    

    }

    .text{
      text-align: left; 
      font-size: 1rem;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 15px;
      color: #505153;
    }
    
    .title{
      font-size: 1.23rem;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color: #2e2e2e;
      margin-bottom: 10px;
      
      text-align: left; /* Alinear el texto a la izquierda */
    }

    .number-column {
      margin-bottom: 6px; /* Agregar un margen entre cada número */
    }
    
    .number {
      width: 50px;
      height: 50px;
      background-color: #f0f0f0; /* Color de fondo predeterminado */
      border-radius: 20%; /* Para hacerlo redondeado */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    
    .square {
      font-size: 28px;
      color: #585858; /* Color del número */
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }
    
    .services-mobile {
      text-align: center;
      height: auto;
    }

    .active {
      background-color: #cec5ff; /* Color de fondo cuando está activo */
    }
    
    .active-square {
      color: #5a39fd; /* Color del número cuando está activo */
    }
}
