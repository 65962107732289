/* Importaciones de Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Estilos personalizados */
.navbar {
  position: fixed;
  top: 25px; /* Distancia desde la parte superior de la página */
  left: 50%; /* Centra horizontalmente */
  transform: translateX(-50%); /* Centra horizontalmente */
  max-width: 1385px; /* Ancho máximo */
  width: calc(100% - 30px); /* Calcula el ancho para que no se desborde */
  height: 100px; /* Altura del navbar */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Alinea los elementos a los lados */
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 80px; /* Bordes más redondeados */
  border: 2px solid #8fadff; /* Borde más oscuro */
 
}


.navbar__logo,
.navbar__links,
.navbar__button {
  border-radius: 30px; /* Bordes más redondeados para los elementos internos */
}

.navbar__logo {
  margin-left: 2vw; /* Espacio a la izquierda del logo */
}

.navbar__links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar__links li:not(:last-child) {
  margin-right: 3vw; /* Espacio entre los enlaces */
}

.navbar__links a {
  color: #404040;
  font-size: 1.2rem;
  position: relative; /* Para que el pseudo-elemento se posicione correctamente */
}

.navbar__links a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Ajusta la posición de la línea */
  width: 100%;
  height: 3px; /* Grosor de la línea */
  background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3); /* Gradiente de colores */
  opacity: 0; /* Inicialmente oculta la línea */
  transition: opacity 0.3s ease-in-out; /* Transición suave de la opacidad */
}

.navbar__links a:hover::after {
  opacity: 1; /* Muestra la línea cuando se pasa el mouse sobre el enlace */
}

.navbar__button {
  background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3);
  color: #ffffff;
  padding: 12px 24px; /* Aumentar el padding para hacer el botón más redondo */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-right: 2vw;
  font-size: 1.19rem; /* Aumentar el tamaño de la letra */
  font-weight: bold;
}

.navbar__button:hover {
  background: linear-gradient(20deg, #8dacff, #5a39fd, #3638ac);
}

.navbar__menu-toggle {
  display: none; /* Oculta el botón de menú desplegable por defecto */
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 15px;

}

.navbar__menu-icon {
  width: 40px;
  height: 3px;
  background-color: #404040;
  transition: transform 0.3s ease-in-out;
  margin : 15px;
  
}


.navbar__menu-icon .line {
  width: 100%;
  height: 3px;
  background-color: #3538CD;
  margin: 5px 0;
  align-items: center;
}

.navbar__menu-icon .line.active {
  width: 100%;
  height: 3px;
  background-color: #3538CD;
  margin: 5px 0;
  align-items: center;
}


.navbar__menu-icon.open {
  transform: scale(1.1)
}

.navbar__menu {
  position: absolute;
  top: 100px; /* Ajusta la posición del menú desplegable */
  right: 20px; /* Ajusta la posición del menú desplegable */
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: none; /* Oculta el menú desplegable por defecto */
  margin-right: 15px;
  left: 0; /* Ajustar la posición del menú al borde izquierdo */
  width: 100%; /* Ancho completo del navbar */
  padding: 10px 30px; /* Añadir padding a los lados del menú */
}



.navbar__menu-button {
  background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3);
  color: #ffffff;
  padding: 12px 24px; /* Aumentar el padding para hacer el botón más redondo */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-right: 2vw;
  font-size: 1.15rem; /* Aumentar el tamaño de la letra */
  font-weight: bold;
  border-radius: 30px;
}

.navbar__menu-button:hover {
  background: linear-gradient(20deg, #8dacff, #5a39fd, #3638ac);
}

.navbar__menu-links li {
  margin-bottom: 13px;
  font-size: 1.15rem; 
}

@media screen and (max-width:970px) {
  .navbar__links {
    display: none;
  }

  .navbar__menu-toggle {
    display: block;
  }

  .navbar__menu {
    display: block;
  }

  .navbar__right {
    display: none;
  }
}