.oferts {
  display: flex;
  flex-direction: column; /* Mostrar como una columna vertical */
  align-items: center;
  background: linear-gradient(#ffffff,  #d4dffc, #c7c8ff, #d4dffc, #ffffff );
 height: auto;
  padding-bottom: 90px;
}

.results__title1 {
  font-size: 2.6rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 30px;
  max-width: 1100px;
  text-align: center; /* Centrar el texto */
}

.animate-text-gradient1 {
  display: inline-flex;
  background-image: linear-gradient(to right, #8dacff, #5a39fd, #3638ac);
  background-size: 200% auto;
  background-clip: text;
  color: transparent;
  font-size: 2.7rem;
  animation: text-gradient 1.5s linear infinite;
}

.oferts-column {
  display: flex;
  flex-direction: column; /* Cambiado a columna en todas las pantallas */
  align-items: center; /* Centrar las tarjetas */
}

.oferts-container {
  display: flex;
  max-width: 1300px;
  width: 100%; /* Cambiado al 100% para ocupar todo el ancho */
  flex-wrap: wrap; /* Permitir el ajuste automático de las tarjetas */
  justify-content: center; /* Centrar las tarjetas en la columna */
}

.oferts-card {
  width: 500px;
  max-width: 500px; /* Ancho máximo de la tarjeta */
  height: 600px;
  background-color: #ffffff;
  border-radius: 20px; /* Aumentar el redondeo de los bordes */
  margin: 20px 10px; /* Espacio vertical de 40px y 10px de margen horizontal */
  position: relative; /* Para posicionar la imagen correctamente */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); 
}

.oferts-card:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3); /* Ajusta los valores para una sombra más grande y suave */
  transform: translateY(-5px);
}

.ofert-image {
  width: 100%;
  height: 45%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.bottom-container {
  position: absolute;
  top: 45%;
  width: 100%;
  height: 12%;
  display: flex;
  padding: 20px;
  border-bottom: 2px solid #bebebe; /* Cambiado a border-top para estar debajo de la imagen */

  align-items: center;
}


.bottom-container img {
  position: absolute;
  left: 20px; /* Ajusta la posición del logotipo */
  margin-top: 5px;
  align-items: center;
}


.card-title {
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  position: absolute;
 left: 85px;
}

.description-container {
  position: absolute;
  top: 57%; /* Posicionamos la descripción desde el 40% de la parte superior */
  height: 43%; /* La descripción ocupará el 60% de la altura de la tarjeta */
  width: 100%; /* Ocupará todo el ancho de la tarjeta */
  padding: 10px;
 
  align-items: center;
}

.description-container p {
  margin: 10px 0;
  font-size: 1.2 rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
}
.description-container article ul li span {
  margin-left: 1px; /* Ajusta el margen izquierdo del texto */
  font-size: 1.1rem !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #2e2e2e;
}
.description-container article ul li span.bold {
  font-weight: bold; /* Hacer el texto en negrita */
}


.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent; /* Tamaño de la flecha */
  border-right: 6px solid transparent; /* Tamaño de la flecha */
  margin-right: 11px; /* Espacio entre la flecha y el texto */
  border-bottom: 10px solid #5a39fd; /* Tamaño de la flecha y color */
}


.description-container ul {
  list-style-type: none; /* Quita los puntos de la lista */
  padding-left: 10; /* Asegura que no haya ningún espacio extra a la izquierda */
}
.description-container li {
  position: relative; /* Hace que la posición de la flecha sea relativa al elemento li */
  padding-left: 5px; /* Espacio para la flecha */
}


.description-container li:before {
  content: ""; /* Añade contenido antes de cada elemento li */
  position: absolute; /* Posiciona la flecha de forma absoluta */
  left: 0; /* Coloca la flecha al principio del elemento li */
  top: 50%; /* Centra verticalmente la flecha */
  transform: translateY(-50%); /* Ajusta la posición vertical de la flecha */
}




@media (max-width: 768px) {
  .oferts-card {
    max-width: 90%; 
    max-width: 370px; /* Eliminar el ancho máximo */
    height: 650px; /* Altura automática */
    margin: 20px auto; /* Espaciado uniforme */
  }


  .oferts {
    display: flex;
    flex-direction: column; /* Mostrar como una columna vertical */
    align-items: center;
    background: linear-gradient(#ffffff,  #d4dffc,   #a2a4ff, #d4dffc, #ffffff  );
    height: auto; 
    
    padding-bottom: 0px;
  }
  
  .bottom-container img {
    position: absolute;
    left: 20px; /* Ajusta la posición del logotipo */
    margin-top: 15px;
    align-items: center;
  }
  .results__title1 {
    font-size: 1.7rem;
    margin-top: 160px;
  }

  .animate-text-gradient1 {
    font-size: 1.8rem;
    animation: text-gradient 1.9s linear infinite;
  }
}

