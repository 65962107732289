.results {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

.results__title {
  font-size: 2.6rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 30px;
  margin-top: 60px;
  max-width: 1100px;
}

.animate-text-gradient {
  display: inline-flex;
  background-image: linear-gradient(to right, #628bfd, #5a39fd, #3638ac);
  background-size: 200% auto;
  background-clip: text;
  color: transparent;
  font-size: 2.7rem;
  animation: text-gradient 2.7s linear infinite;
}

.results__cards {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@keyframes text-gradient {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -200% 0;
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra las tarjetas horizontalmente */
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  margin-top: 30px;
}

.card {
  width: 375px;
  height: 260px;
  background-color: #edf2ff; /* Fondo blanco dentro de la tarjeta */
  border-radius: 15px; /* Radio de borde para el contenedor de la tarjeta */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 3px solid #1d20a3; /* Borde sólido con color #1d20a3 */
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), box-shadow 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Transición suave para el efecto hover */
  margin-bottom: 35px;
  justify-content: center; /* Centra las tarjetas en dispositivos móviles */
  margin-left: 10px;
  margin-right: 10px;
  margin: 10px auto;
}

.card__content {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.card:hover {
  transform: scale(1.03); /* Escala aumentada en el efecto hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}
.card__image {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  display: block;
}

.card__title {
  font-size: 55px;
  font-weight: bold;
  color: #5a39fd;
  margin-top: 0;
  margin-bottom: 5px;
}

.card__subtitle {
  font-size: 20px;
  color: #252525; /* Color del texto */
  font-weight: 600;
}

.card__subtitle strong {
  font-weight: bold;
  padding: 0.2em 0.4em;
  background: #cec5ff;
  border-radius: 10px;
  color: #5a39fd; /* Violeta muy oscuro */
}
@media screen and (max-width: 768px) {
  .card {
    width: 320px; /* Ancho reducido en dispositivos móviles */
    justify-content: center; /* Centra las tarjetas en dispositivos móviles */
  }
 
}

@media screen and (max-width: 560px) {
  .cards {
    justify-content: center; /* Centra las tarjetas en dispositivos móviles */
  }
  
  .card {
    width: calc(100% - 40px); /* Ancho completo con margen en dispositivos móviles */
    max-width: 350px; /* Ancho máximo en dispositivos móviles */
    margin: 10px; /* Espacio entre las tarjetas */
  }
}

/* Media query agregada para dispositivos móviles con ancho máximo de 600px */
@media screen and (max-width: 600px) {
  .results__title {
    font-size: 1.8rem;
    max-width: 100%;
    text-align: center;
  }
  .card {
    width: 3500px; /* Ancho reducido en dispositivos móviles */
  }
  .animate-text-gradient {
    display: inline-flex;
    background-image: linear-gradient(to right, #8dacff, #5a39fd, #3638ac);
    background-size: 200% auto;
    background-clip: text;
    color: transparent;
    font-size: 1.8rem;
    animation: text-gradient 1.9s linear infinite;
  }
  @keyframes text-gradient {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -200% 0;
    }
  }
}
