@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');


.home__subtitle-container {
  max-width: 600px; /* Ancho máximo para el subtítulo */
  margin: 20px auto; /* Centra horizontalmente con un poco de espacio superior */
}


.home {
   
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Alinea el contenido en la parte superior */
    margin-top: 100px; /* Controla la distancia superior */
}

.home__content {
  text-align: center;
  padding-top: 50px; /* Agrega espacio arriba */
}

.home__title {
  font-size: 3rem;
  line-height: 1.6; /* Espacio entre líneas */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color:#2e2e2e ;
  margin-bottom: 30px
}
.home__subtitle {
  font-size: 1.2rem;
  line-height: 1.5; /* Espacio entre líneas */
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color:#555555 ;
  margin-bottom: 20px
  
}


.home__strong {
  font-weight: bold;
  padding: 0.2em 0.4em;
  background: #cccdff;
  border-radius: 10px;
  color: #3538ce; /* Violeta muy oscuro */

}

.home__highlight {
  color: #ff6600; /* Color naranja para destacar */
  font-family: 'Poppins', sans-serif;
}

.home__highlight1 {
    color: #3538CD; /* Color naranja para destacar */
    font-family: 'Poppins', sans-serif;
  }

.home__button {
    background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3);
    color: #ffffff;
    padding: 12px 24px; /* Aumentar el padding para hacer el botón más redondo */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-right: 2vw;
    font-size: 1.5rem; /* Aumentar el tamaño de la letra */
    font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  border-radius: 50px;
}

.home__container {
  max-width: 1350px;
  margin: 0 auto; /* Centra el contenido horizontalmente */
}


.home__button:hover {
background: linear-gradient(20deg, #8dacff, #5a39fd, #3638ac);
      
}

.home__content {
    display: grid;
    grid-template-columns: 6fr 6fr; /* Una columna de 8 fracciones y otra de 4 fracciones */
    gap: 20px; /* Separación entre las columnas */
  }
  
  .home__text-column {
    padding: 20px; /* Espaciado interno */
    text-align: left; /* Justifica el contenido */
    
  }
  
  .home__video-column {
    margin-top: 60px;
  
  }
  
  .home__video {
    width: 100%; /* Ajusta el tamaño del video */
    height: auto;
    
  }
  
  @media screen and (max-width: 900px) {
   
    .home__video-column{
      display: none;
    }
    .home__content {
      grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos más pequeños */
    }
  
    .home__video-column {
      order: 2; /* Cambia el orden del video para que vaya al final en dispositivos más pequeños */
    }

    .home__content {
      text-align: center;
      padding-top: 50px; /* Agrega espacio arriba */
      text-align: justify; /* Justifica el contenido */
    }
   
  
    
    .home__title {
      font-size: 2.2rem;
      line-height: 1.6; /* Espacio entre líneas */
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#2e2e2e ;
      margin-bottom: 30px
    }
    
    .home__subtitle {
      font-size: 1.2rem;
      line-height: 1.6; /* Espacio entre líneas */
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#6e6e6e ;
      margin-bottom: 20px;
      margin-left: 0; /* Agregar margen izquierdo */
    }

    .home {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start; /* Alinea el contenido en la parte superior */
      margin-top: 70px; /* Controla la distancia superior */
  }
  }
  
  @media screen and (max-width: 1200px) {

    .home__title {
      font-size: 2.4rem;
      line-height: 1.6; /* Espacio entre líneas */
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#2e2e2e ;
      margin-bottom: 30px
    }
    
    .home__subtitle {
      font-size: 1.3rem;
      line-height: 1.5; /* Espacio entre líneas */
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#555555 ;
      margin-bottom: 20px
      
    }
  }

  @media screen and (max-width: 970px) {
    .home__content {
      grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos más pequeños */
    }
  
    .home__video-column {
      order: 2; /* Cambia el orden del video para que vaya al final en dispositivos más pequeños */
    }

    .home__content {
      text-align: center;
      padding-top: 50px; /* Agrega espacio arriba */
    }
    
    .home__title {
      font-size: 2.2rem;
      line-height: 1.6; /* Espacio entre líneas */
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#2e2e2e ;
      margin-bottom: 30px
    }
    
    .home__subtitle {
      font-size: 1.2rem;
      line-height: 1.5; /* Espacio entre líneas */
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#6e6e6e ;
      margin-bottom: 20px
      
    }

    .home {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start; /* Alinea el contenido en la parte superior */
      margin-top: 70px; /* Controla la distancia superior */
  }

  .home__video-column{
      display: none;
    }
  @media screen and (max-width: 600px) {
    .home__title br {
      display: none;
    }
    .home__video-column{
      display: none;
    }

    .home__title {
      font-size: 1.9rem;
      line-height: 1.4; /* Espacio entre líneas */
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#2e2e2e ;
      margin-bottom: 30px
    }
    
    .home__subtitle {
      font-size: 1.11rem;
      line-height: 1.6; /* Espacio entre líneas */
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color:#6e6e6e ;
      margin-bottom: 20px;
      margin-left: 0; /* Agregar margen izquierdo */
    }
  }
  
    
}
  