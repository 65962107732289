/* Título */
.pricing-title {
  font-size: 2.6rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 60px;
  margin-top: 10px;
  max-width: 1100px;
  text-align: center;
  margin-left: auto; /* Centra el título horizontalmente */
  margin-right: auto; /* Centra el título horizontalmente */
}

/* Contenedor principal */
.pricing-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  border: 2px solid #4723fd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  padding: 0; /* Elimina el padding para evitar desbordamientos */
  box-sizing: border-box; /* Incluye padding y borde en el ancho total */
  margin-bottom: 80px;

}

/* Efecto hover para toda la tarjeta */
.pricing-container:hover {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2), 0 0 15px rgba(72, 35, 253, 0.3);
  border-color: #5a86ff;
}

/* Columna Izquierda */
.pricing-left {
  flex: 5;
  background-color: #e6e6ff;
  text-align: center;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing-left h1 {
  font-size: 3rem;
  margin: 0;
  color: #4723fd;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.pricing-left p {
  font-size: 1.2rem;
  color: #444444;
}

.pricing-button {
  margin-top: 20px;
  padding: 12px 20px;
  background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3);
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.pricing-button:hover {
  background: linear-gradient(20deg, #8dacff, #5a39fd, #3638ac);
}

/* Columna Derecha */
.pricing-right {
  flex: 8;
  background-color: #ffffff;
  padding: 35px;
}

.checklist {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.checklist li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #444444;
}

.check-icon {
  color: #4723fd;
  margin-right: 10px;
}

.note {
  font-size: 0.875rem;
  color: #888888;
  margin-top: 20px;
  line-height: 1.6;
}

/* Responsiveness */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
  }

  .pricing-left,
  .pricing-right {
    flex: 100%;
    padding: 20px;
  }

  .pricing-left h1 {
    font-size: 2.7rem;
  }

  .pricing-button {
    font-size: 1.2rem;
  }

  .pricing-title {
    font-size: 1.8rem;
    margin-bottom: 60px;
    margin-top: 10px;
  }

  .pricing-container {
   
    margin-bottom: 1.1
    0px;
   
    margin-bottom: 0px;
 
   
  }

  .checklist li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #444444;
  }
  .note {
    font-size: 0.775rem;
    color: #888888;
    margin-top: 20px;
    line-height: 1.6;
  }
 
  .faq-card h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #2e2e2e;
  }
  
  .faq-card p {
    overflow: hidden;
    font-size: 18px; /* Tamaño de la fuente de las respuestas */
    line-height: 1.5; /* Espaciado entre líneas */
    color: #2e2e2e;
    margin-bottom: 15px; /* Espacio entre párrafos */
    margin-top: 15px;
  }
 

}

@media (max-width: 1300px) {

  .pricing-container {
 margin-left: 100px;
  }
}