body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #5a86ff, #4723fd, #1d20a3); /* Gradient de colores para el pulgar */
  border-radius: 6px; /* Radio de borde del pulgar */
  animation: scrollGradient 3s ease infinite alternate; 
  cursor: pointer; /* Cambia el cursor a un puntero */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #4676fc, #3912ff, #0b0ea0); /* Gradient de colores para el pulgar al hacer hover */
   cursor: pointer; /* Cambia el cursor a un puntero */
}

::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #ffffff, #d4dffc); /* Gradient de colores para el track */
 
}

@keyframes scrollGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}