/* BlurryBackground.css */
.blurry-background {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Ancho del componente */
    max-width: 1360px; /* Ancho máximo del efecto de desenfoque */
    height: 25px; /* Altura de la sección desenfocada */
    background: rgba(255, 255, 255, 0.05); /* Color transparente con opacidad */
    backdrop-filter: blur(10px); /* Aplicar desenfoque */
    z-index: 999; /* Asegúrate de que esté por encima del contenido */
  }
  
  @media screen and (max-width: 768px) {
    .blurry-background {
      width: 100%; /* Ocupar toda la pantalla en dispositivos móviles */
      left: 0;
      transform: none; /* Reiniciar la transformación para centrado */
    }
  }