.contact {
  display: flex;
  flex-direction: column; /* Alinear los elementos en una columna */
  align-items: center; /* Centrar horizontalmente los elementos */
  height: auto; /* Ajusta la altura según sea necesario */

}

.cal-container {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  margin-top: 10px; /* Espacio superior */
 
}

.results__title2 {
  font-size: 2.6rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 30px;
  max-width: 1100px;
  text-align: center; /* Centrar el texto */
}

@media (max-width: 800px) {
  
  .results__title2 {
    font-size: 1.7rem;
    margin-top: 60px;
  }

  .animate-text-blue{
    font-size: 1.7rem;
  }


}




