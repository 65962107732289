/* Testimonials.css */

.testimonials-container {
    max-width: 1200px;
    margin: auto;
    padding: 40px;
}

.swiper-container {
    background: url('Background.png') no-repeat center center;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
}

.testimonial-slide {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    padding: 60px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    width: 100%;
    min-height: 400px; /* Altura mínima en pantallas grandes */
    text-align: center; /* Centra el texto */
    justify-content: flex-start; /* Alinea el contenido al inicio verticalmente */
}

.company-logo {
    width: 200px; /* Ajusta a un tamaño uniforme */
    height: auto; /* Mantiene la proporción */
    margin-bottom: 30px;
    position: relative;
    left: -230px; /* Ajusta la posición a la izquierda */
}

.quote {
    font-size: 1.5rem;
    line-height: 1.7;
    margin-bottom: 30px;
    max-width: 675px;
    text-align: justify; /* Justifica el texto */
}

.author-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.author-image {
    width: 60px; /* Ajusta el tamaño de la imagen */
    height: 60px; /* Mantiene la proporción */
    border-radius: 50%; /* Hace que la imagen sea redondeada */
    object-fit: cover; /* Asegura que la imagen cubra el área sin distorsión */
    
  
}

.author {
    font-size: 1.2rem;
    font-weight: 300;
}

/* Paginación personalizada */
.swiper-pagination {
    position: absolute;
    bottom: 30px; /* Aumenta la separación del fondo */
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px); /* Ajusta el ancho para compensar el padding */
    padding: 0 30px;
}

.swiper-pagination-bullet {
    background: #ffffff;
    opacity: 0.5;
    width: 35px; /* Ajusta el ancho del rectángulo */
    height: 6px; /* Ajusta la altura del rectángulo */
    margin: 0 15px; /* Ajusta el espaciado entre los rectángulos */
    border-radius: 3px; /* Bordes redondeados para los rectángulos */
}

.swiper-pagination-bullet-active {
    opacity: 1;
}

/* Responsive design */
@media (max-width: 768px) {
    .testimonial-slide {
        padding: 40px;
        min-height: 550px; /* Más alto en móviles */
    }

    .quote {
        font-size: 1.3rem;
    }

    .company-logo {
        width: 150px;
        left: -75px; 
    }

    .author-image {
        width: 50px; /* Ajusta para pantallas medianas */
        height: 50px;
    }

    .author {
        font-size: 1.1rem;
        font-weight: 300;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center; /* Opcional, para centrar también verticalmente */
        margin-top: 20px; /* Ajusta el espacio superior si es necesario */
      }

     
      .testimonials__button {
    
        font-size: 1.4rem; /* Aumenta el tamaño de la letra */
        
      }
}

@media (max-width: 480px) {
    .testimonial-slide {
        padding: 35px;
        padding-top: 50px;
        min-height: 550px; /* Aún más alto en pantallas muy pequeñas */
    }

    .quote {
        font-size: 1.15rem;
        text-align: center;
    }

    .company-logo {
        width: 150px;
        left: -50px; 
    }
    .testimonials__button {
    
        font-size: 1.1rem; /* Aumenta el tamaño de la letra */
        
      }

    .author-image {
        width: 50px; /* Ajusta para pantallas pequeñas */
        height: 50px;
    }

    .author {
        font-size: 1.1rem;
        font-weight: 300;
    }

}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Opcional, para centrar también verticalmente */
    margin-top: 60px; /* Ajusta el espacio superior si es necesario */
    margin-bottom: 20px;
  }
  
  /* Estilos del botón */
  .testimonials__button {
    background: linear-gradient(20deg, #5a86ff, #4723fd, #1d20a3);
    color: #ffffff;
    padding: 12px 24px; /* Aumenta el padding para hacer el botón más redondo */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-size: 1.6rem; /* Aumenta el tamaño de la letra */
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    border-radius: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  /* Estilos del botón al pasar el mouse */
  .testimonials__button:hover {
    background: linear-gradient(20deg, #8dacff, #5a39fd, #3638ac);
  }


  @media (max-width: 768px) {
    .testimonials__button {
      font-size: 1.6rem; /* Reduce el tamaño de la letra en dispositivos móviles */
      padding: 10px 20px; /* Ajusta el padding para que el botón mantenga su proporción */
    }
  }
  
  @media (max-width: 480px) {
    .testimonials__button {
      font-size: 1.4rem; /* Reduce aún más el tamaño de la letra en pantallas muy pequeñas */
      padding: 8px 16px; /* Ajusta el padding acorde al tamaño del texto */
    }
  }