.faqs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: linear-gradient(#ffffff, #d4dffc, #d4dffc, #c7c8ff); /* Fondo gradient */
  height: auto;
  font-family: 'Poppins', sans-serif; /* Fuente global */
}

.faq-card {
  padding: 20px;
  margin-bottom: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border: 2px solid #8fadff; /* Borde más oscuro */
}

.faq-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #f6f7fa;
  border: 3px solid #8fadff; 
}

.faq-card.open {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.faq-card h2 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #2e2e2e;
}

.faq-card p {
  overflow: hidden;
  font-size: 18px; /* Tamaño de la fuente de las respuestas */
  line-height: 1.5; /* Espaciado entre líneas */
  color: #2e2e2e;
  margin-bottom: 15px; /* Espacio entre párrafos */
  margin-top: 15px;
}

.faq-card ul {
  padding-left: 20px; /* Espaciado a la izquierda para listas */
}

.faq-card li {
  font-size: 18px; /* Tamaño de la fuente igual al del texto en los párrafos */
  line-height: 1.5; /* Espaciado entre líneas en las listas */
  color: #2e2e2e; /* Color de texto en listas igual al del resto del texto */
}

.faq-card strong {
  font-weight: bold;
  color: #000000; /* Color negro para el texto en negrita */
}

.title-divider {
  height: 20px; /* Ajusta la distancia entre el título y las tarjetas */
}

.faq-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px; /* Ancho máximo para la fila de tarjetas */
}

.faq-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.faq-button.open {
  transform: rotate(180deg);
}
