.carousel-container {
    max-width: 1300px;
    margin: 70px auto;
    padding: 20px 0;
  
  }
  
  .carousel-container .infinite-carousel-wrapper {
    margin: 20px 0;
  }
  .w-36 {
    max-width: 180px; /* Establece el ancho máximo */
    height: auto; /* Permite que la altura se ajuste automáticamente */
  }
  

  @media screen and (max-width: 900px) {

    .carousel-container {
      max-width: 1300px;
      margin: 5px auto;
      padding: 20px 0;
    }
  }